import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COUPON_QUERY";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeQuotationsStatusToKr } from "@sellernote/_shared/src/utils/forwarding/bid";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import QuotationCouponModal from "./UserCouponModal";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

const boxLabelStyle: SxProps<Theme> = {
  flex: "0 0 auto",
};

function QuotationsUser({
  shipmentDetail,
}: {
  shipmentDetail: AdminBidDetail;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const { quotationsUser } = shipmentDetail;

  const [showsQuotationCouponModal, setShowsQuotationCouponModal] =
    useState(false);

  const [_, setSessionInvoiceId] = useSessionStorage<string | null>(
    "invoiceId"
  );

  const { mutate: downloadBidQuotation } =
    ADMIN_BID_QUERY.useDownloadBidQuotation(shipmentDetail.id);

  const { mutate: cancelCoupon } = ADMIN_COUPON_QUERY.useCancelCoupon();

  const handleMoveToTradingStatementPageClick = useCallback(() => {
    const invoiceId = shipmentDetail?.bidInvoice
      ? shipmentDetail.bidInvoice.id.toString()
      : null;

    // 거래명세서에서 페이지에서 사용할 명세서 고유 아이디 저장
    setSessionInvoiceId(invoiceId);

    history.push(
      `/tradingStatement/invoice/${shipmentDetail.id}/${
        shipmentDetail.isImport ? "import" : "export"
      }`
    );
  }, [
    shipmentDetail.bidInvoice,
    shipmentDetail.id,
    shipmentDetail.isImport,
    history,
    setSessionInvoiceId,
  ]);

  const handleBidQuotationDownload = useCallback(() => {
    downloadBidQuotation(
      {},
      {
        onSuccess: ({ data: successData }) => {
          window.location.href = successData.preSignedUrl.url;
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [downloadBidQuotation, handleSnackbarOpen]);

  const handleCouponDelete = (couponCode: string) => {
    cancelCoupon(
      { couponCode, pathParams: { shipmentId: shipmentDetail.id } },
      {
        onSuccess: () => {
          handleSnackbarOpen("쿠폰을 취소했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentDetail.id,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen("쿠폰취소에 실패했습니다.", "error");
        },
      }
    );
  };

  const showsCouponSendButton = (() => {
    const { projectStatus } = shipmentDetail;

    return (
      projectStatus === "beforeContactPartner" ||
      projectStatus === "contactingPartner" ||
      projectStatus === "scheduling" ||
      projectStatus === "canceled" ||
      projectStatus === "moving" ||
      projectStatus === "portEntryAndPrepareCustoms"
    );
  })();

  const isMoveToTradingStatementButtonDisabled = (() => {
    const { status, projectStatus, management } = shipmentDetail;

    const isStatusWaitingOrWaitingForExporterInfo =
      status === "waiting" || status === "waitingForExporterInfo";

    const isProjectStatusBeforeMoving =
      projectStatus === "beforeContactPartner" ||
      projectStatus === "contactingPartner" ||
      projectStatus === "scheduling";

    const isProjectStatusMovingWithoutDetailStatus =
      projectStatus === "moving" && management.detailStatus === null;

    return (
      isStatusWaitingOrWaitingForExporterInfo ||
      isProjectStatusBeforeMoving ||
      isProjectStatusMovingWithoutDetailStatus
    );
  })();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">확정견적</Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          {APP_NAME === "shipda-admin" && (
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/bid/change-user-quotation/${shipmentDetail.id}`);
              }}
            >
              견적서 수정
            </Button>
          )}

          {APP_NAME === "shipda-admin" && (
            <Button
              variant="contained"
              disabled={isMoveToTradingStatementButtonDisabled}
              onClick={handleMoveToTradingStatementPageClick}
              color="info"
            >
              거래명세서 생성
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => {
              handleBidQuotationDownload();
            }}
            endIcon={<DownloadIcon />}
            color="secondary"
          >
            견적서 다운로드
          </Button>
        </Box>
      </Box>

      {quotationsUser.map((quotation) => {
        return (
          <Box
            key={quotation.id}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              columnGap: 1,
              rowGap: "4px",
            }}
          >
            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>견적 담당자:</Typography>

              <Typography>셀러노트</Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>제출가:</Typography>

              <Typography>{`${toThousandUnitFormat(
                quotation.totalPrice
              )}원`}</Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>제출일자:</Typography>

              <Typography>
                {toFormattedDate(quotation.createdAt, "YYYY-MM-DD")}
              </Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>유효기간:</Typography>

              <Typography>
                {toFormattedDate(quotation.expiredAt, "YYYY-MM-DD")}
              </Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>상태:</Typography>

              <Typography>
                {changeQuotationsStatusToKr(quotation.status)}
              </Typography>
            </Box>

            {APP_NAME === "shipda-admin" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>자동견적 여부:</Typography>

                <Typography> {shipmentDetail.isFixed ? "O" : "X"}</Typography>
              </Box>
            )}

            {showsCouponSendButton && APP_NAME === "shipda-admin" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>쿠폰 등록:</Typography>

                <Button
                  disabled={shipmentDetail.coupon !== null}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowsQuotationCouponModal(true);
                  }}
                  variant="text"
                >
                  등록
                </Button>
              </Box>
            )}

            {APP_NAME === "shipda-admin" && shipmentDetail?.coupon && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>쿠폰 삭제:</Typography>

                <Button
                  disabled={!shipmentDetail.coupon}
                  onClick={(e) => {
                    handleCouponDelete(shipmentDetail.coupon.code);
                  }}
                  size="small"
                  color="error"
                  variant="text"
                >
                  삭제
                </Button>
              </Box>
            )}
          </Box>
        );
      })}

      <QuotationCouponModal
        freightType={shipmentDetail.freightType}
        setShowsQuotationCouponModal={setShowsQuotationCouponModal}
        showsQuotationCouponModal={showsQuotationCouponModal}
        teamId={shipmentDetail.teamId}
        userId={shipmentDetail.userId}
        shipmentId={shipmentDetail.id}
      />
    </Box>
  );
}

export default QuotationsUser;
