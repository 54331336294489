import { useCallback, useMemo, useState } from "react";
import { Button, Checkbox } from "antd";
import { useAtomValue } from "jotai";

import { APP_ENV } from "@sellernote/_shared/src/constants";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  PurchaseManagementInfo,
  SalesManagementInfo,
  TrelloBidInvoice,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { checkIfHaveAuthorityToChangeTrelloDetail } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import useSnackbar from "../../../../../../hooks/useSnackbar";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../jotaiStates/auth";
import RejectSettlementCommentModal from "../RejectSettlementCommentModal";
import SettlementValidationModal from "./SettlementValidationModal";
import useProfitTableData from "./useProfitTableData";
import Styled from "./index.styles";

/** Dev 환경에서는 임의로 설정한 teamId를 사용함(#15266) */
const WE_DEALIZE_TEAM_ID = APP_ENV === "production" ? 20539 : 553;

const ProfitTable = ({
  teamId,
  bidInvoice,
  bidId,
  projectStatus,
  salesManagementInfo,
  purchaseManagementInfo,
}: {
  teamId: number;
  bidInvoice: TrelloBidInvoice | null;
  bidId: number;
  projectStatus: BidProjectStatus;
  salesManagementInfo: SalesManagementInfo;
  purchaseManagementInfo: PurchaseManagementInfo;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showRejectCommentModal, setShowRejectCommentModal] = useState(false);
  const [showsSettlementValidationModal, setShowsSettlementValidationModal] =
    useState(false);
  const [hasDeposit, setHasDeposit] = useState(false);

  const {
    mutate: confirmSettlement,
    ResponseHandler: ResponseHandlerOfConfirmSettlement,
  } = TRELLO_BID_QUERY.useConfirmSettlement({
    bidId,
  });

  const {
    mutate: requestSettlement,
    ResponseHandler: ResponseHandlerOfRequestSettlement,
  } = TRELLO_BID_QUERY.useRequestSettlement({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        if (showsSettlementValidationModal) {
          setShowsSettlementValidationModal(false);
        }
      },
      customizeMessage: () => ({
        title: "정산요청을 완료했습니다.",
      }),
    },
    bidId,
  });

  const { mutate: checkRequestingSettlement } =
    TRELLO_BID_QUERY.useCheckRequestingSettlement(bidId);

  const { salesManagementTableData, purchaseManagementTableData } =
    useProfitTableData({
      salesManagementInfo,
      purchaseManagementInfo,
    });

  const salesTotalPrice = useMemo(() => {
    const totalPrice = salesManagementTableData.reduce((acc, cur) => {
      acc += cur.price;

      return acc;
    }, 0);

    return Math.round(totalPrice);
  }, [salesManagementTableData]);

  const purchaseTotalPrice = useMemo(() => {
    const totalPrice = purchaseManagementTableData.reduce((acc, cur) => {
      acc += cur.price - (cur?.taxExemptPrice ?? 0);

      return acc;
    }, 0);

    return Math.round(totalPrice);
  }, [purchaseManagementTableData]);

  const profitOrLossPrice = useMemo(() => {
    return salesTotalPrice - purchaseTotalPrice;
  }, [purchaseTotalPrice, salesTotalPrice]);

  const getProfitOrLossPercent = useCallback(() => {
    if (salesTotalPrice === 0) {
      return 0;
    }
    return (
      ((salesTotalPrice - purchaseTotalPrice) / salesTotalPrice) *
      100
    ).toFixed(2);
  }, [purchaseTotalPrice, salesTotalPrice]);

  const getSuccessMessageByProjectStatus = useCallback(
    (status: BidProjectStatus) => {
      switch (status) {
        case "inSettlement":
          return "정산 카드를 승인했습니다.";
        case "settlementConfirmed":
          return "정산 카드를 2차 승인했습니다.";
        case "settlementSecondConfirmed":
          return "정산 카드를 완료했습니다.";
        default:
          return "-";
      }
    },
    []
  );

  const getSettlementConfirmButtonName = useCallback(
    (status: BidProjectStatus) => {
      switch (status) {
        case "inSettlement":
          return "마감 1차 승인";
        case "settlementConfirmed":
          return "마감 2차 승인";
        case "settlementSecondConfirmed":
          return "마감 완료";
        default:
          return "-";
      }
    },
    []
  );

  const getSettlementStatus: (
    projectStatus: BidProjectStatus
  ) =>
    | Extract<
        BidProjectStatus,
        | "settlementConfirmed"
        | "settlementSecondConfirmed"
        | "settlementComplete"
      >
    | undefined = useCallback((projectStatus: BidProjectStatus) => {
    switch (projectStatus) {
      case "inSettlement":
        return "settlementConfirmed";
      case "settlementConfirmed":
        return "settlementSecondConfirmed";
      case "settlementSecondConfirmed":
        return "settlementComplete";
    }
  }, []);

  const handleSettlementRequestClick = useCallback(() => {
    /**
     * 쉽다와 위딜라이즈와의 거래는 별도의 사업자와의 거래가 아니라 마감 요청 조건을 간소화 (#15264)
     */
    if (teamId === WE_DEALIZE_TEAM_ID) {
      requestSettlement({
        salesAmount: salesTotalPrice,
        purchaseAmount: purchaseTotalPrice,
      });
      return;
    }

    checkRequestingSettlement(
      {},
      {
        onSuccess: () => {
          requestSettlement({
            salesAmount: salesTotalPrice,
            purchaseAmount: purchaseTotalPrice,
          });
        },

        onError: (error) => {
          if (error.response?.data.errorCode === "E184") {
            setHasDeposit(false);
          }

          if (error.response?.data.errorCode === "E185") {
            setHasDeposit(true);
          }

          setShowsSettlementValidationModal(true);
          return;
        },
      }
    );
  }, [
    checkRequestingSettlement,
    purchaseTotalPrice,
    requestSettlement,
    salesTotalPrice,
    teamId,
  ]);

  const handleSettlementConfirmClick = useCallback(
    (projectStatus: BidProjectStatus) => {
      confirmSettlement(
        {
          acceptSettlement: true,
          settlementStatus: getSettlementStatus(projectStatus),
        },
        {
          onSuccess: () => {
            handleSnackbarOpen(getSuccessMessageByProjectStatus(projectStatus));
          },
        }
      );
    },
    [
      confirmSettlement,
      getSettlementStatus,
      getSuccessMessageByProjectStatus,
      handleSnackbarOpen,
    ]
  );

  const isInvoice = useCallback(() => {
    if (bidInvoice && !bidInvoice.depositDate) {
      return true;
    }
    return false;
  }, [bidInvoice]);

  const settlementConfirmButtonDisabled = useMemo(() => {
    return (
      checkIfHaveAuthorityToChangeTrelloDetail(
        currentAdminAuthInfo?.authority,
        projectStatus
      ) || isInvoice()
    );
  }, [isInvoice, currentAdminAuthInfo?.authority, projectStatus]);

  const handleRejectSettlementClick = useCallback(() => {
    setShowRejectCommentModal(true);
  }, []);

  const getProfitItemNameToKr = useCallback((v: string) => {
    switch (v) {
      case "invoice":
        return "거래명세서";
      case "warehouseReceipt":
        return "창고료";
      case "etcDeposit":
        return "기타입금";
      case "refund":
        return "부킹 커미션";
      case "foreign":
        return "해외파트너";
      case "domestic":
        return "국내파트너";
      case "customs":
        return "관세사";
      case "shipDaCustoms":
        return "쉽다관세사";
      case "duty":
        return "세관";
      case "inland":
        return "내륙운송사";
      case "wareHouse":
        return "창고";
      case "shipping":
        return "선사";
      case "etc":
        return "기타파트너";
      case "parcel":
        return "화물 택배사";
      default:
        return "-";
    }
  }, []);

  return (
    <Styled.container>
      <Styled.profitTable>
        <thead>
          <tr>
            <th>구분</th>

            <th>공급가</th>

            <th>계산서</th>
          </tr>
        </thead>

        <tbody>
          <tr className="sales">
            <td>매출</td>

            <td>{toThousandUnitFormat(salesTotalPrice)}원</td>

            <td></td>
          </tr>

          {salesManagementTableData.map((v, i) => {
            return (
              <tr key={i}>
                <td>{getProfitItemNameToKr(v.invoiceType)}</td>

                <td>{toThousandUnitFormat(v?.price.toFixed(0))}원</td>

                <td>
                  <Checkbox checked={v.issueFlag}></Checkbox>
                </td>
              </tr>
            );
          })}

          <tr className="sales">
            <td>매입</td>
            <td>{toThousandUnitFormat(purchaseTotalPrice)}원</td>
            <td></td>
          </tr>

          {purchaseManagementTableData.map((v, i) => {
            return (
              <tr key={i}>
                <td>{getProfitItemNameToKr(v.partnerType)}</td>
                <td>
                  {toThousandUnitFormat(
                    ((v?.price ?? 0) - (v?.taxExemptPrice ?? 0)).toFixed(0)
                  )}
                  원
                </td>
                <td>
                  <Checkbox checked={v?.withdrawalFlag}></Checkbox>
                </td>
              </tr>
            );
          })}

          <tr className="sales">
            <td>손익</td>

            <td>{toThousandUnitFormat(profitOrLossPrice)}원</td>

            <td>{getProfitOrLossPercent()}%</td>
          </tr>
        </tbody>
      </Styled.profitTable>

      {(projectStatus === "finished" ||
        projectStatus === "settlementRejected") && (
        <div className="button-container">
          <Button
            className="button"
            disabled={checkIfHaveAuthorityToChangeTrelloDetail(
              currentAdminAuthInfo?.authority,
              projectStatus
            )}
            onClick={handleSettlementRequestClick}
          >
            마감 요청
          </Button>
        </div>
      )}

      {(projectStatus === "inSettlement" ||
        projectStatus === "settlementConfirmed" ||
        projectStatus === "settlementSecondConfirmed") && (
        <div className="button-container">
          <Button
            className="button"
            disabled={checkIfHaveAuthorityToChangeTrelloDetail(
              currentAdminAuthInfo?.authority,
              projectStatus
            )}
            onClick={handleRejectSettlementClick}
          >
            반려
          </Button>

          <Button
            className="button"
            disabled={settlementConfirmButtonDisabled}
            onClick={() => handleSettlementConfirmClick(projectStatus)}
          >
            {getSettlementConfirmButtonName(projectStatus)}
          </Button>
        </div>
      )}

      {ResponseHandlerOfRequestSettlement}

      {ResponseHandlerOfConfirmSettlement}

      {showRejectCommentModal && (
        <RejectSettlementCommentModal
          showRejectCommentModal={showRejectCommentModal}
          setShowRejectCommentModal={setShowRejectCommentModal}
          bidId={bidId}
        />
      )}

      {showsSettlementValidationModal && (
        <SettlementValidationModal
          hasDeposit={hasDeposit}
          setShowsSettlementValidationModal={setShowsSettlementValidationModal}
          showsSettlementValidationModal={showsSettlementValidationModal}
          onSuccessButtonClick={() => {
            requestSettlement({
              salesAmount: salesTotalPrice,
              purchaseAmount: purchaseTotalPrice,
            });
          }}
        />
      )}
    </Styled.container>
  );
};

export default ProfitTable;
