import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { AccessTime, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY, {
  CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  ForwardingAdminAttachment,
  ForwardingAdminFileDomain,
} from "@sellernote/_shared/src/types/common/file";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  hasDayPassed,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import {
  changeFileAuthorityToKr,
  changeFileDocumentDomainToKr,
  checkCustomsClearanceStartFlag,
  checkIfHaveAuthorityToChangeTrelloDetail,
} from "@sellernote/_shared/src/utils/forwarding/admin/trello";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import UploadModal from "@sellernote/_shared-for-forwarding-admin/src/containers/UploadModal";

import FileName from "../../../../../containers/FileName";
import useSnackbar from "../../../../../hooks/useSnackbar";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../../../../components/LegacyTable";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../jotaiStates/auth";
import CreateDocument from "./CreateDocument";
import FileHistoryModal from "./FileHistoryModal";
import RequiredDocumentModal from "./RequiredDocumentModal";
import useCheckHasAttachment from "./useCheckHasAttachement";
import useTrelloUploadDocumentsTableData from "./useTrelloUploadDocumentsTableData";

type CellId = keyof ForwardingAdminAttachment | "upload";

const UploadDocuments = ({
  trelloDetail,
}: {
  trelloDetail: TrelloBidDetail;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const queryClient = useQueryClient();

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [showsRequiredDocumentModal, setShowsRequiredDocumentModal] =
    useState(false);
  const [type, setType] = useState("");
  const [showsFileHistoryModal, setShowsFileHistoryModal] = useState(false);

  const [showsDocumentReviewConfirmModal, setShowsDocumentReviewConfirmModal] =
    useState(false);

  const { data: attachments } = TRELLO_BID_QUERY.useGetTrelloAttachments({
    bidId: trelloDetail.id,
  });

  const { mutate: startCustomsClearance } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useStartCustomsClearance(trelloDetail.id);

  const {
    mutate: requestDocumentReview,
    ResponseHandler: ResponseHandlerOfRequestDocumentReview,
  } = TRELLO_BID_QUERY.useRequestDocumentReviewToCustoms({
    bidId: trelloDetail.id,
    onSuccess: () => {
      setShowsDocumentReviewConfirmModal(false);
      handleSnackbarOpen("서류검토를 정상적으로 요청했습니다.");
    },

    onError: () => {
      handleSnackbarOpen("서류검토 요청에 실패했습니다.", "error");
    },

    disablesModal: () => {
      setShowsDocumentReviewConfirmModal(false);
    },
  });

  const { trelloUploadDocumentTableData } = useTrelloUploadDocumentsTableData({
    isImport: trelloDetail.isImport,
    attachments,
  });

  const {
    hasCIPLAttachment,
    hasCOAttachment,
    hasBrnAttachment,
    hasCertificateAttachment,
    hasEtcAttachment,
  } = useCheckHasAttachment(attachments);

  const handleCustomsClearanceStart = useCallback(
    (status: "quarantine" | "selectivity" | "inProgress") => {
      const message =
        status === "inProgress"
          ? "통관 시작"
          : status === "quarantine"
          ? "검역진행 중"
          : "검사 선별";

      startCustomsClearance(
        {
          status,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen(`${message}을 확인했습니다.`);
            queryClient.invalidateQueries(
              CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.all()
            );
          },

          onError: () => {
            handleSnackbarOpen(`${message} 요청에 실패했습니다.`, "error");
          },
        }
      );
    },
    [handleSnackbarOpen, queryClient, startCustomsClearance]
  );

  const handleUploadModalOpen = useCallback(
    (value: ForwardingAdminFileDomain) => {
      return () => {
        setType(value);
        setShowsUploadModal(true);
      };
    },
    []
  );

  const handleRequiredDocumentModalOpen = useCallback(() => {
    setShowsRequiredDocumentModal(true);
  }, []);

  const handleFileHistoryModalClose = useCallback(() => {
    setShowsFileHistoryModal(false);
  }, []);

  const handleFileHistoryModalOpen = useCallback(() => {
    setShowsFileHistoryModal(true);
  }, []);

  const getFileDocumentTableDomain = useCallback(
    (domain: ForwardingAdminFileDomain) => {
      if (
        domain === "houseDo" ||
        domain === "releaseRequest" ||
        domain === "parcelRequest" ||
        domain === "adminTemp"
      ) {
        return (
          <Tooltip title="이 구분은 '쉽다-어드민'만 표시됩니다.">
            <Grid container alignContent="center">
              {changeFileDocumentDomainToKr(domain, trelloDetail.isImport)}

              <Person color="primary" />
            </Grid>
          </Tooltip>
        );
      }

      return changeFileDocumentDomainToKr(domain, trelloDetail.isImport);
    },
    [trelloDetail.isImport]
  );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "domain",
        disablePadding: false,
        label: "구분",
        width: 100,
        verticalAlign: "baseline",
      },
      {
        id: "upload",
        disablePadding: false,
        label: "새 파일",
        width: 90,
        verticalAlign: "baseline",
      },
      {
        id: "name",
        disablePadding: false,
        label: "파일 이름",
        width: 190,
        verticalAlign: "baseline",
      },
      {
        id: "description",
        disablePadding: false,
        label: "파일 설명",
        width: 150,
        verticalAlign: "baseline",
      },
      {
        id: "author",
        disablePadding: false,
        label: "올린 사람",
        width: 150,
        verticalAlign: "baseline",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "올린 일시",
        width: 150,
        verticalAlign: "baseline",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!trelloUploadDocumentTableData) return [];

    return trelloUploadDocumentTableData.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        domain: getFileDocumentTableDomain(v.domain),
        upload:
          v.domain !== "HBL" ? (
            <Button variant="text" onClick={handleUploadModalOpen(v.domain)}>
              업로드
            </Button>
          ) : (
            ""
          ),
        name: (
          <List>
            {v.attachments.map((n) => {
              return (
                <ListItem key={n.id} disablePadding>
                  {n.createdAt && !hasDayPassed(n.createdAt) && (
                    <Typography variant="caption" mr={0.5} color="orange">
                      New)
                    </Typography>
                  )}
                  <ListItemText
                    primary={
                      <FileName
                        projectStatus={trelloDetail.projectStatus}
                        id={trelloDetail.id}
                        attachment={n}
                        dataType={"trello"}
                        fileCategory={changeFileDocumentDomainToKr(
                          n.domain,
                          trelloDetail.isImport
                        )}
                      />
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ),
        description: (
          <List>
            {v.attachments.map((n) => {
              return (
                <ListItem key={n.id} disablePadding>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ height: 24 }}
                        variant="body1"
                        component="div"
                      >
                        {omitWithEllipsis({
                          src: n.description,
                          maxLength: 10,
                          ellipsis: "...",
                        })}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ),
        author: (
          <List>
            {v.attachments.map((n) => {
              return (
                <ListItem key={n.id} disablePadding>
                  <ListItemText
                    primary={`${changeFileAuthorityToKr(n.authority)} ${
                      n.author
                    }`}
                  />
                </ListItem>
              );
            })}
          </List>
        ),
        createdAt: (
          <List>
            {v.attachments.map((n) => {
              return (
                <ListItem key={n.id} disablePadding>
                  <ListItemText
                    primary={toFormattedDate(
                      n.createdAt,
                      "YYYY-MM-DD a hh:mm:ss"
                    )}
                  />
                </ListItem>
              );
            })}
          </List>
        ),
      };

      return row;
    });
  }, [
    getFileDocumentTableDomain,
    handleUploadModalOpen,
    trelloDetail.id,
    trelloDetail.isImport,
    trelloDetail.projectStatus,
    trelloUploadDocumentTableData,
  ]);

  const customDomain = trelloDetail.accountPayables.filter((v) => {
    return v.domain === "customs";
  });

  const disablesDocumentReviewRequestButton = (() => {
    if (!customDomain.length || !customDomain[0].partner) return true;

    return !(
      customDomain[0].partner.isConfirmed &&
      customDomain[0].partner.businessArea === "shipDaCustoms"
    );
  })();

  return (
    <Grid container direction="column" sx={{ mb: "16px", mt: 2 }}>
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ color: blue[700] }}
          >
            서류 업로드
          </Typography>
        </Grid>

        <Grid item xs={5} alignItems="center">
          <Button
            variant="outlined"
            onClick={handleFileHistoryModalOpen}
            startIcon={<AccessTime />}
          >
            히스토리
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          spacing={1}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            {APP_NAME === "shipda-admin" &&
              trelloDetail.management.invoiceCbm && (
                <CreateDocument
                  bidId={trelloDetail.id}
                  userCompany={trelloDetail?.user?.engCompany}
                />
              )}
          </Grid>

          {/* 입황완료 및 통관 준비에서만 노출 */}

          <>
            {APP_NAME === "partner-admin" && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={
                    checkCustomsClearanceStartFlag(
                      trelloDetail.accountPayables
                    ) ||
                    trelloDetail.projectStatus !== "portEntryAndPrepareCustoms"
                  }
                  className="customs-button"
                  onClick={() => handleCustomsClearanceStart("inProgress")}
                >
                  통관 시작
                </Button>
              </Grid>
            )}

            {APP_NAME === "partner-admin" && (
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleCustomsClearanceStart("selectivity")}
                  disabled={
                    // 통관 시작 후 활성화 가능
                    !checkCustomsClearanceStartFlag(
                      trelloDetail.accountPayables
                    ) ||
                    trelloDetail.projectStatus !== "portEntryAndPrepareCustoms"
                  }
                >
                  검사 선별
                </Button>
              </Grid>
            )}

            {APP_NAME === "partner-admin" && (
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  disabled={
                    // 통관 시작 후 활성화 가능 뒷 단계 요청시 앞 단계는 불가능
                    !checkCustomsClearanceStartFlag(
                      trelloDetail.accountPayables
                    ) ||
                    trelloDetail.projectStatus !== "portEntryAndPrepareCustoms"
                  }
                  onClick={() => handleCustomsClearanceStart("quarantine")}
                >
                  검역 진행 중
                </Button>
              </Grid>
            )}
          </>

          {APP_NAME === "shipda-admin" && trelloDetail.isImport && (
            <Grid item>
              <Button
                variant="contained"
                sx={{ background: "orange" }}
                onClick={() => setShowsDocumentReviewConfirmModal(true)}
                disabled={
                  (disablesDocumentReviewRequestButton &&
                    // 통관 시작 후 활성화 가능 뒷 단계 요청시 앞 단계는 불가능
                    !checkCustomsClearanceStartFlag(
                      trelloDetail.accountPayables
                    )) ||
                  trelloDetail.projectStatus !== "portEntryAndPrepareCustoms"
                }
              >
                서류 검토 요청
              </Button>
            </Grid>
          )}

          {trelloDetail.isImport && (
            <Grid item>
              <Button
                variant="contained"
                color="success"
                disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                  currentAdminAuthInfo?.authority,
                  trelloDetail.projectStatus
                )}
                onClick={handleRequiredDocumentModalOpen}
              >
                서류 제출 요청
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <LegacyTable headCells={headCells} rows={rows} />

      {showsUploadModal && (
        <UploadModal
          setShowsUploadModal={setShowsUploadModal}
          showsUploadModal={showsUploadModal}
          type={type}
          id={type === "brn" ? trelloDetail.userId : trelloDetail.id}
          dataType={"trello"}
          isPermission={type === "brn" ? false : true}
          teamId={trelloDetail.teamId}
          trelloId={trelloDetail.id}
        />
      )}

      {showsRequiredDocumentModal && (
        <RequiredDocumentModal
          showRequiredDocumentModal={showsRequiredDocumentModal}
          setShowRequiredDocumentModal={setShowsRequiredDocumentModal}
          trelloDetail={trelloDetail}
          hasCIPLAttachment={hasCIPLAttachment}
          hasCOAttachment={hasCOAttachment}
          hasBrnAttachment={hasBrnAttachment}
          hasCertificateAttachment={hasCertificateAttachment}
          hasEtcAttachment={hasEtcAttachment}
        />
      )}

      {showsFileHistoryModal && (
        <FileHistoryModal
          showsFileHistoryModal={showsFileHistoryModal}
          handleFileHistoryModalClose={handleFileHistoryModalClose}
          bidId={trelloDetail.id}
          isImport={trelloDetail.isImport}
        />
      )}

      {showsDocumentReviewConfirmModal && (
        <Modal
          isOpened={showsDocumentReviewConfirmModal}
          handleClose={() => setShowsDocumentReviewConfirmModal(false)}
          modalBody={
            <Box
              sx={{
                height: 150,
                width: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">
                {customDomain[0].partner.name}에게 서류 검토를 요청하시겠습니까?
              </Typography>

              <Box>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setShowsDocumentReviewConfirmModal(false)}
                >
                  취소
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginLeft: 3 }}
                  onClick={() => requestDocumentReview({})}
                >
                  요청
                </Button>
              </Box>
            </Box>
          }
        />
      )}

      {ResponseHandlerOfRequestDocumentReview}
    </Grid>
  );
};

export default UploadDocuments;
