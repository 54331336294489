import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  WarehouseDocumentForm,
  WarehouseDocumentFormType,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getDocumentDataCbm,
  getDocumentDataWeight,
  getWarehouseDocumentTextFieldLabel,
} from "@sellernote/_shared/src/utils/forwarding/admin/trello";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

import useSnackbar from "../../../../../../../hooks/useSnackbar";

import useWarehouseDocumentPreview from "./useWarehouseDocumentPreview";
import Styled from "./index.styles";

const WarehouseDocument = ({
  warehouseDocumentDrawerVisible,
  setWarehouseDocumentDrawerVisible,
  bidId,
}: {
  warehouseDocumentDrawerVisible: boolean;
  setWarehouseDocumentDrawerVisible: Dispatch<SetStateAction<boolean>>;
  bidId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: warehouseDocumentDefaultData } =
    TRELLO_BID_QUERY.useGetWarehouseDocumentDefaultData(bidId);

  const {
    setValue,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<WarehouseDocumentForm>();

  const { mutate: updateDocument, isLoading } =
    TRELLO_BID_QUERY.useUpdateDocument(bidId);

  const { mutate: updateWarehouseInfo } =
    TRELLO_BID_QUERY.useUpdateWarehouseInfo(bidId);

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const warehouseDocumentHtml = useWarehouseDocumentPreview(
    watch,
    warehouseDocumentDefaultData?.docNum || ""
  );

  useEffect(() => {
    reset({
      warehouseName: warehouseDocumentDefaultData?.warehouseName || "",
      adminName: warehouseDocumentDefaultData?.adminName.includes("매니저")
        ? warehouseDocumentDefaultData?.adminName
        : `${warehouseDocumentDefaultData?.adminName} 매니저`,
      adminPhone: warehouseDocumentDefaultData?.adminPhone,
      blNumber: warehouseDocumentDefaultData?.blNumber,
      company: warehouseDocumentDefaultData?.company,
      packageInfo: warehouseDocumentDefaultData?.packageInfo,
      faxNumber: "0505-901-7210",
      adminEmail: "cs.fw@ship-da.com",
      cbm: warehouseDocumentDefaultData?.invoiceCbm
        ? getDocumentDataCbm(warehouseDocumentDefaultData?.invoiceCbm)
        : warehouseDocumentDefaultData?.cbm,
      weight: warehouseDocumentDefaultData?.invoiceCbm
        ? getDocumentDataWeight(warehouseDocumentDefaultData?.invoiceCbm)
        : warehouseDocumentDefaultData?.weight,
      comment:
        "・DO와 면장 전달드립니다. <br> ・세금계산서 발행정보 <br> - 사업자등록번호: 256-81-01498 <br> - 메일주소:  tax@ship-da.com",
    });
  }, [
    reset,
    warehouseDocumentDefaultData?.adminName,
    warehouseDocumentDefaultData?.adminPhone,
    warehouseDocumentDefaultData?.blNumber,
    warehouseDocumentDefaultData?.cbm,
    warehouseDocumentDefaultData?.company,
    warehouseDocumentDefaultData?.invoiceCbm,
    warehouseDocumentDefaultData?.packageInfo,
    warehouseDocumentDefaultData?.warehouseName,
    warehouseDocumentDefaultData?.weight,
  ]);

  const handleDocumentDownload: SubmitHandler<WarehouseDocumentForm> =
    useCallback(
      (data) => {
        updateDocument(
          { fileData: { ...data, type: "releaseRequest" } },
          {
            onSuccess: ({ data: uploadSuccessData }) => {
              getFileDownloadUrl(
                {
                  pathParams: {
                    key: uploadSuccessData.pdfKey,
                  },
                },
                {
                  onSuccess: async ({ data: downloadSuccessData }) => {
                    const blobFile = await axios.get(downloadSuccessData.url, {
                      responseType: "blob",
                    });

                    fileDownload(blobFile.data, uploadSuccessData.fileName);
                    queryClient.invalidateQueries(
                      TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId })
                    );
                  },

                  onError: () => {
                    handleSnackbarOpen(
                      "파일 다운로드에 실패했습니다.",
                      "error"
                    );
                  },
                }
              );
            },

            onError: () => {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            },
          }
        );
      },
      [
        bidId,
        getFileDownloadUrl,
        handleSnackbarOpen,
        queryClient,
        updateDocument,
      ]
    );

  const handleDocumentUpload: SubmitHandler<WarehouseDocumentForm> =
    useCallback(
      (data) => {
        updateDocument(
          { fileData: { ...data, type: "releaseRequest" } },
          {
            onSuccess: () => {
              handleSnackbarOpen("업로드에 성공했습니다.");
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId })
              );
            },

            onError: () => {
              handleSnackbarOpen("업로드에 실패했습니다.", "error");
            },
          }
        );
      },
      [bidId, handleSnackbarOpen, queryClient, updateDocument]
    );

  const handleWarehouseInfoUpdate = useCallback(() => {
    updateWarehouseInfo(
      {},
      {
        onSuccess: ({ data: successData }) => {
          if (successData?.warehouseName) {
            setValue("warehouseName", successData.warehouseName || "");
            handleSnackbarOpen("창고 정보가 업데이트 됐습니다.");
            return;
          }

          handleSnackbarOpen("창고명이 없습니다.", "error");
          setValue("warehouseName", "");
        },

        onError: ({ response }) => {
          if (response?.data?.error === "bid not found") {
            handleSnackbarOpen("BL번호 등록에 문제가 있습니다.", "error");
          }

          if (response?.data?.errorCode === "E351") {
            handleSnackbarOpen("유니패스 데이터 결과가 없습니다.", "error");
          }

          if (response?.data?.errorCode === "E367") {
            handleSnackbarOpen(
              "보세창고 부호가 등록되지 않았거나 찾지 못했습니다.",
              "error"
            );
          }
          setValue("warehouseName", "");
        },
      }
    );
  }, [handleSnackbarOpen, setValue, updateWarehouseInfo]);

  const handleDocumentDrawerClose = useCallback(() => {
    setWarehouseDocumentDrawerVisible(false);
  }, [setWarehouseDocumentDrawerVisible]);

  const handleTextFieldClear = useCallback(
    (formType: WarehouseDocumentFormType) => {
      return () => {
        reset({ ...watch(), [`${formType}`]: "" });
      };
    },
    [reset, watch]
  );

  const FormTextField = useCallback(
    ({
      textFieldError,
      textFieldType,
      multiline,
      rows,
      width,
    }: {
      textFieldError: FieldError | undefined;
      textFieldType: WarehouseDocumentFormType;
      multiline?: boolean;
      rows?: number;
      width?: number;
    }) => {
      return (
        <Controller
          control={control}
          name={textFieldType}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                multiline={multiline}
                rows={rows}
                inputRef={ref}
                sx={{ width: width }}
                handleTextFieldClear={handleTextFieldClear(textFieldType)}
                label={getWarehouseDocumentTextFieldLabel(textFieldType)}
                error={textFieldError && textFieldError.type === "required"}
                helperText={
                  textFieldError &&
                  textFieldError.type === "required" &&
                  "필수 입력입니다."
                }
              />
            );
          }}
        />
      );
    },
    [control, handleTextFieldClear]
  );

  return (
    <Drawer
      anchor={"left"}
      open={warehouseDocumentDrawerVisible}
      onClose={handleDocumentDrawerClose}
    >
      <Styled.drawerBody>
        <Styled.header>
          <div>{warehouseDocumentDefaultData?.docNum || "-"}</div>
        </Styled.header>

        <Divider />

        <Styled.documentContainer>
          <Box
            component="form"
            autoComplete="off"
            className="create-container"
            onSubmit={handleSubmit(handleDocumentUpload)}
          >
            <div className="title">
              <Typography variant="h6" component="div">
                창고료 내역서 요청 및 서류 전달
              </Typography>
            </div>

            <div className="input-container">
              <div className="warehouse-name">
                <FormTextField
                  textFieldError={errors.warehouseName}
                  textFieldType={"warehouseName"}
                />
              </div>

              <div className="company">
                <FormTextField
                  textFieldError={errors.company}
                  textFieldType={"company"}
                />
              </div>

              <div className="bl-number">
                <FormTextField
                  textFieldError={errors.blNumber}
                  textFieldType={"blNumber"}
                />
              </div>

              <div className="package-container">
                <FormTextField
                  textFieldError={errors.packageInfo}
                  textFieldType={"packageInfo"}
                />

                <FormTextField
                  textFieldError={errors.weight}
                  textFieldType={"weight"}
                />

                <FormTextField
                  textFieldError={errors.cbm}
                  textFieldType={"cbm"}
                />
              </div>

              <div className="comment">
                <FormTextField
                  textFieldError={errors.comment}
                  textFieldType={"comment"}
                  width={600}
                  multiline={true}
                  rows={3}
                />
              </div>

              <div className="partner-name">
                <FormTextField
                  textFieldError={errors.adminName}
                  textFieldType={"adminName"}
                />

                <FormTextField
                  textFieldError={errors.adminPhone}
                  textFieldType={"adminPhone"}
                />

                <FormTextField
                  textFieldError={errors.faxNumber}
                  textFieldType={"faxNumber"}
                />

                <FormTextField
                  textFieldError={errors.adminEmail}
                  textFieldType={"adminEmail"}
                />
              </div>
            </div>

            <Styled.uploadButton>
              <Button color="success" variant="contained" type="submit">
                {isLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "업로드"
                )}
              </Button>
            </Styled.uploadButton>

            <Styled.downloadButton>
              <Button
                variant="contained"
                onClick={handleSubmit(handleDocumentDownload)}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "다운로드"
                )}
              </Button>
            </Styled.downloadButton>

            <Styled.updateWarehouseInfoButton>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleWarehouseInfoUpdate}
              >
                창고명
              </Button>
            </Styled.updateWarehouseInfoButton>
          </Box>

          <Styled.preview
            dangerouslySetInnerHTML={{
              __html: warehouseDocumentHtml,
            }}
          />
        </Styled.documentContainer>
      </Styled.drawerBody>
    </Drawer>
  );
};

export default WarehouseDocument;
