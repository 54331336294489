import { useMemo, useState } from "react";
import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { checkCanEditBidItem } from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

import ItemUpdateModal from "../components/ItemUpdateModal";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../jotaiStates/auth";
import MsdsDownload from "../../MsdsDownload";
import FCLItemList from "./FCLItemList";
import LCLAndAIRItemList from "./LCLAndAIRItemList";

export default function GeneralCargoInfo({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const totalQuantity = useMemo(() => {
    if (!bidDetail.itemGroupList) return;

    return bidDetail.itemGroupList.reduce((sum, itemGroup) => {
      if (bidDetail.freightType === "FCL") {
        const subSum =
          itemGroup.containerInfo?.reduce((a, c) => {
            return a + c.quantity;
          }, 0) || 0;

        return sum + subSum;
      } else {
        return sum + (itemGroup.item.quantity ?? 0);
      }
    }, 0);
  }, [bidDetail.freightType, bidDetail.itemGroupList]);

  const [showsItemUpdateModal, setShowsItemUpdateModal] = useState(false);

  const handleItemUpdateModalOpen = () => {
    setShowsItemUpdateModal(true);
  };

  const handleItemUpdateModalClose = () => {
    setShowsItemUpdateModal(false);
  };

  const cargoMetaInfoList: {
    label: string | undefined;
    value: React.ReactNode;
  }[] = [
    { label: "운송수단", value: bidDetail.freightType },
    {
      label: "총 물동량",
      value: getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply),
    },
    {
      label: "총 수량",
      value: toThousandUnitFormat(totalQuantity),
    },
    ...(APP_NAME === "shipda-admin" && bidDetail.attachments.length > 0
      ? [
          {
            label: undefined,
            value: (
              <MsdsDownload
                bidId={bidDetail.id}
                attachments={bidDetail.attachments}
              />
            ),
          },
        ]
      : []),
    // TODO: 총 중량도 표기해달라는 요청이 있었음. (현재는 애매해서 일단 둠)
  ];

  return (
    <Card>
      <Grid container spacing={"10px"} padding={"10px"}>
        <Grid container item xs={10}>
          {cargoMetaInfoList.map((v, i) => (
            <Grid
              container
              item
              xs={2}
              alignItems={"center"}
              key={i}
              direction={"column"}
              spacing={1}
            >
              {v.label && (
                <Grid item>
                  <Typography variant="body2" sx={{ color: grey[600] }}>
                    {v.label}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="body1">{v.value}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={2} textAlign={"right"}>
          {APP_NAME === "shipda-admin" && (
            <Button
              variant="contained"
              disabled={checkCanEditBidItem(
                bidDetail.projectStatus,
                currentAdminAuthInfo?.authority
              )}
              onClick={handleItemUpdateModalOpen}
            >
              화물정보 수정
            </Button>
          )}
        </Grid>
      </Grid>

      <Divider />

      {bidDetail.freightType === "FCL" && <FCLItemList bidDetail={bidDetail} />}
      {(bidDetail.freightType === "LCL" || bidDetail.freightType === "AIR") && (
        <LCLAndAIRItemList bidDetail={bidDetail} />
      )}

      {showsItemUpdateModal && (
        <ItemUpdateModal
          bidDetail={bidDetail}
          onItemUpdateModalClose={handleItemUpdateModalClose}
        />
      )}
    </Card>
  );
}
