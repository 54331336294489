import { Box, Button, TextField, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIfHaveAuthorityToChangeTrelloDetail } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import ZoneAddressTextFiled from "../../../../../../../containers/ZoneAddressTextField";

import Modal from "../../../../../../../components/Modal";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../../jotaiStates/auth";
import useEndAddressFormLogic from "./useEndAddressFormLogic";

function EndAddressFormModal({
  trelloDetail,
  opensEndAddressFormModal,
  setOpensEndAddressFormModal,
  bidAccountPayableId,
}: {
  opensEndAddressFormModal: boolean;
  setOpensEndAddressFormModal: (value: boolean) => void;
  trelloDetail: TrelloBidDetail;
  bidAccountPayableId: number | undefined;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const {
    zoneName,
    setZoneName,
    setZoneId,
    setEndAddressDetail,
    endAddressDetail,
    handleInlandTypeRegisterAndMoveToTradingStatement,
    handleInlandTransportChange,
    ResponseHandlerOfRegisterInland,
    ResponseHandlerOfUseCancelInlandTransport,
    ResponseHandlerOfEditInlandType,
    handleInlandTypeCancel,
  } = useEndAddressFormLogic({
    trelloDetail,
    bidAccountPayableId,
    setOpensEndAddressFormModal,
  });

  return (
    <Modal
      isOpened={opensEndAddressFormModal}
      handleClose={() => {
        setOpensEndAddressFormModal(false);
      }}
      modalBody={
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "400px" }}
          gap={2}
        >
          <Typography variant="h6">도착지 내륙</Typography>

          <Box>
            <Typography>주소</Typography>

            <ZoneAddressTextFiled
              zoneName={zoneName}
              pageType="trello"
              setTrelloZoneNameState={setZoneName}
              setTrelloZoneIdState={setZoneId}
            />
          </Box>

          <Box>
            <Typography>상세주소</Typography>

            <TextField
              fullWidth
              onChange={(e) => {
                setEndAddressDetail(e.target.value);
              }}
              value={endAddressDetail}
              sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            />
          </Box>

          <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
            <Button
              color="error"
              variant="contained"
              onClick={handleInlandTypeCancel}
            >
              내륙 운송 취소
            </Button>

            {!trelloDetail.endAddress && trelloDetail.endAddress !== "" ? (
              <Button
                variant="contained"
                disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                  currentAdminAuthInfo?.authority,
                  trelloDetail.projectStatus
                )}
                onClick={handleInlandTypeRegisterAndMoveToTradingStatement}
              >
                확인
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                  currentAdminAuthInfo?.authority,
                  trelloDetail.projectStatus
                )}
                onClick={handleInlandTransportChange}
              >
                확인
              </Button>
            )}
          </Box>

          {ResponseHandlerOfRegisterInland}

          {ResponseHandlerOfUseCancelInlandTransport}

          {ResponseHandlerOfEditInlandType}
        </Box>
      }
    />
  );
}

export default EndAddressFormModal;
