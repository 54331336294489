import { useMemo } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Checkbox, Grid, Tooltip, Typography } from "@mui/material";

import { GET_FINANCIAL_DEPOSIT_HISTORY_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeInvoiceTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import Table, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../../../components/Table";

import { DepositHistoryTableCellId } from "..";

function MatchedInvoiceDepositHistoryTable({
  checkBoxSet,
  toggleCheckBox,
  toggleTotalMatchedInvoiceDepositAmount,
  matchedInvoiceDepositHistoryData,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
}: {
  checkBoxSet: Set<number>;
  toggleCheckBox: (value: number) => void;
  toggleTotalMatchedInvoiceDepositAmount: (
    value: Record<number, number>
  ) => void;
  matchedInvoiceDepositHistoryData:
    | GET_FINANCIAL_DEPOSIT_HISTORY_RES
    | undefined;
  perPage: number;
  setPerPage: (value: number) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}) {
  const headCells: TableHeadCell<DepositHistoryTableCellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: "선택",
      },
      {
        id: "transactionDateTime",
        disablePadding: false,
        label: "입금일시",
        width: 120,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "수취은행",
      },
      {
        id: "depositName",
        disablePadding: false,
        label: "입금자명",
        width: 150,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
      },
      {
        id: "depositAmount",
        disablePadding: false,
        label: "입금액",
      },
      {
        id: "remark",
        disablePadding: false,
        label: (
          <Tooltip title="입금액이 다른 거래명세서에 매칭된 경우 내용을 알 수 있습니다.">
            <Grid container alignItems={"baseline"}>
              <Typography variant="body2">비고</Typography>

              <InfoOutlinedIcon sx={{ fontSize: "12px", cursor: "pointer" }} />
            </Grid>
          </Tooltip>
        ),
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!matchedInvoiceDepositHistoryData?.list) return [];

    return matchedInvoiceDepositHistoryData.list
      .slice(currentPage * perPage, currentPage * perPage + perPage)
      .map((v) => {
        const row: TableBodyRow<DepositHistoryTableCellId> = {
          checkbox: (
            <Checkbox
              checked={checkBoxSet.has(v.id)}
              onClick={(e) => {
                toggleCheckBox(v.id);
                toggleTotalMatchedInvoiceDepositAmount({
                  [v.id]: v.depositAmount,
                });
              }}
            />
          ),
          transactionDateTime: v.transactionDateTime,
          bankName: v.bankName,
          depositName: v.depositName,
          currency: v.currency,
          depositAmount: toThousandUnitFormat(v.depositAmount),
          remark: v.remarkFinalPriceTotal !== 0 && (
            <Grid container direction="column" textAlign="right">
              <Grid item>{toThousandUnitFormat(v.remarkFinalPriceTotal)}</Grid>

              {v.remark?.map((n) => {
                return (
                  <Grid item key={n.invoiceId}>
                    <Typography variant="body2">{`(${
                      n.bidId
                    } ${changeInvoiceTypeToKr(n.invoiceType)})`}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          ),
        };

        return row;
      });
  }, [
    checkBoxSet,
    currentPage,
    matchedInvoiceDepositHistoryData?.list,
    perPage,
    toggleCheckBox,
    toggleTotalMatchedInvoiceDepositAmount,
  ]);

  return (
    <Table
      toolbarItems={{
        left: [
          <Box key={"sub-title"}>
            <Typography variant="body2" component="span">
              거래명세서에 등록된 입금내역입니다.
            </Typography>
          </Box>,
        ],
      }}
      headCells={headCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [5],
        totalCount: matchedInvoiceDepositHistoryData?.total || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
    />
  );
}

export default MatchedInvoiceDepositHistoryTable;
